export const COMMAND_DISPENSER_STATE = 'ST';
export const COMMAND_DISPENSER_RESET = 'RS';
export const COMMAND_RF_ENCODE_POSITION = 'RF';
export const COMMAND_DISPERSE_TO_FRONT = 'DC';
export const COMMAND_DISPERSE_AND_HOLD = 'DH';

export const COMMAND_ENABLE_INTAKING_CARD = 'EI';
export const COMMAND_INTAKE_CARD = 'IC';
export const COMMAND_STOP_INTAKING = 'SI';
export const COMMAND_RETREIVE_TO_STACKER = 'RE';

export const sendDispenserCommand = (command, wsClient) => {
  wsClient.send(command);
};
