var W3CWebSocket = require('websocket').w3cwebsocket;

export const create = (controllerWebsocketServer, reject) => {
  var client = new W3CWebSocket(controllerWebsocketServer, 'echo-protocol');

  client.onerror = function() {
    reject('Connection Error');
  };

  /* client.onclose = function() {
    console.log('echo-protocol Client Closed');
  }; */

  return client;
};