import {
  sendDispenserCommand,
  COMMAND_DISPENSER_RESET,
  COMMAND_RF_ENCODE_POSITION,
  COMMAND_DISPERSE_AND_HOLD,
  COMMAND_DISPENSER_STATE,
  COMMAND_ENABLE_INTAKING_CARD,
  COMMAND_INTAKE_CARD,
  COMMAND_STOP_INTAKING,
  COMMAND_RETREIVE_TO_STACKER,
} from './dispenserHelper';
import { create as wsHelperCreate } from './wsHelper';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
import { STORAGE_ACCESS_PROPERTY_DATA } from '@/constants/storage';

const COMPLETE_STATE = 4;

let issueState = 0;
let resetDone = false;

export const issueCard = async (dateFrom, dateTo, room, encoderNo, kioskSettings, controllerWebsocketServer, encodeCallback, postEncodeCallback) => {

  // Only encoder...
  if (parseInt(process.env.VUE_APP_DEBUG_CARD_ISSUE, 10) === 1) {
    return new Promise((resolve, reject) => {
      if (!encodeCallback) {
        return reject();
      }

      let wsClient = null;
      try {
        wsClient = wsHelperCreate(controllerWebsocketServer, reject);
      } catch (e) {
        return reject(e);
      }

      wsClient.onopen = function() {
        issueState = 1;
        Storage.get({
          key: STORAGE_ACCESS_PROPERTY_DATA,
        }).then(({ value }) => {
          const accessData = value ? JSON.parse(value) : null;
          sendCurrentHotelPin(wsClient, accessData ? accessData['pin'] : '');
        });
        setTimeout(() => {
          encodeCallback(wsClient, encoderNo, kioskSettings, dateFrom, dateTo, room);
        }, 300);
      };

      wsClient.onmessage = function(e) {
        console.log(issueState, e.data);
        if (typeof e.data === 'string') {
          const response = mapResponseData(e.data);
          if (response[0] === 'AC0') {
            console.log('Sent successfully'); // Nothing to do now
          } else if (response[0] === 'CC0') {
            resolve(response[0]);
          } else {
            wsClient.close();
            return reject(e);
          }
        }
      };
    });
  }

  // Debug, without dispenser
  if (parseInt(process.env.VUE_APP_DEBUG, 10) === 1) {
    return new Promise((resolve) => {
      resolve();
    });
  }

  return new Promise((resolve, reject) => {
    if (!encodeCallback) {
      return reject();
    }

    let wsClient = null;
    try {
      wsClient = wsHelperCreate(controllerWebsocketServer, reject);
    } catch (e) {
      return reject(e);
    }

    wsClient.onopen = function() {
      issueState = -1;
      Storage.get({
        key: STORAGE_ACCESS_PROPERTY_DATA,
      }).then(({ value }) => {
        const accessData = value ? JSON.parse(value) : null;
        sendCurrentHotelPin(wsClient, accessData ? accessData['pin'] : '');
      });
      setTimeout(() => {
        sendDispenserCommand(COMMAND_DISPENSER_STATE, wsClient);
      }, 300);
    };

    wsClient.onmessage = function(e) {
      console.log(issueState, e.data);
      if (typeof e.data === 'string') {
        const response = mapResponseData(e.data);
        if (response[0] === 'AC0') {
          console.log('Sent successfully'); // Nothing to do now
        } else if (response[0] === 'CC0') {
          try {
            if (issueState === -1) {
              console.log('CC0 - RF encode position');
              issueState = 1;
              sendDispenserCommand(COMMAND_RF_ENCODE_POSITION, wsClient);
            } else if (issueState === 0 && resetDone) {
              console.log('CC0 - disperse after reset');
              issueState = 1;
              if (kioskSettings.encoder_address === '0.0.0.0') {
                console.log('CC0 - skipping encode, sending again RF ENCODE');
                sendDispenserCommand(COMMAND_RF_ENCODE_POSITION, wsClient);
                return;
              }
              sendDispenserCommand(COMMAND_RF_ENCODE_POSITION, wsClient);
            } else if (issueState === 1) {
              issueState = 2;
              if (kioskSettings.encoder_address === '0.0.0.0') {
                console.log('CC0 - skipping encode, sending again RF ENCODE');
                sendDispenserCommand(COMMAND_RF_ENCODE_POSITION, wsClient);
                return;
              }
              console.log('CC0 - encode');
              encodeCallback(wsClient, encoderNo, kioskSettings, dateFrom, dateTo, room);
            } else if (issueState === 2) {
              issueState = 3;
              if (typeof postEncodeCallback === 'function') {
                postEncodeCallback(wsClient, e.data.replace('CC0;', ''), dateFrom, dateTo, room);
                issueState = COMPLETE_STATE;
                console.log('CC0 - hold');
                sendDispenserCommand(COMMAND_DISPERSE_AND_HOLD, wsClient);
              } else {
                postEncodeCallback(wsClient, e.data.replace('CC0;', ''), dateFrom, dateTo, room)
                  .then(() => {
                    issueState = COMPLETE_STATE;
                    console.log('CC0 - hold');
                    sendDispenserCommand(COMMAND_DISPERSE_AND_HOLD, wsClient);
                  })
                  .catch((e) => {
                    return reject(e);
                  });
              }
            } else if (issueState === 3) {
              issueState = COMPLETE_STATE;
              console.log('CC0 - hold - 3');
              sendDispenserCommand(COMMAND_DISPERSE_AND_HOLD, wsClient);
            } else if (issueState === COMPLETE_STATE) {
              resolve();
            }

          } catch (e) {
            return reject(e);
          }
        } else {
          if (resetDone || issueState > 0) { // Already reseted device and still getting an error
            wsClient.close();
            return reject(e);
          }

          // First error, reset device
          issueState = 0;
          resetDone = true;
          resetDispenser(wsClient);
        }
      }
    };

    wsClient.onclose = function() {
      resetDone = false;
    };

    wsClient.onerror = function(e) {
      console.log('Issue with WS: ', e);
      return reject(e);
    };
  });
};

export const lostKeyHandler = (roomCode, encoderNo, controllerWebsocketServer, kioskSettings, lostKeyHelper, wsClient) => {
  if (parseInt(process.env.VUE_APP_DEBUG, 10) === 1) {
    return new Promise((resolve) => {
      resolve();
    });
  }

  return new Promise((resolve, reject) => {
    if (!lostKeyHelper) {
      return resolve();
    }

    const onOpenFunction = function() {
      issueState = 1;
      Storage.get({
        key: STORAGE_ACCESS_PROPERTY_DATA,
      }).then(({ value }) => {
        const accessData = value ? JSON.parse(value) : null;
        console.log('sending pin', wsClient, accessData ? accessData['pin'] : '');
        sendCurrentHotelPin(wsClient, accessData ? accessData['pin'] : '');
      });
      setTimeout(() => {
        try {
          lostKeyHelper(wsClient, encoderNo, kioskSettings, roomCode);
        } catch(e) {
          console.log('Checkout card error', e);
        }
        resolve();
      }, 300);
    };

    let dontTerminateConnection = false;
    if (!wsClient) {
      try {
        wsClient = wsHelperCreate(controllerWebsocketServer, reject);
      } catch (e) {
        return reject(e);
      }
    } else {
      dontTerminateConnection = true;
      onOpenFunction();
    }

    wsClient.onopen = onOpenFunction;

    wsClient.onmessage = function(e) {
      console.log(issueState, e.data);
      if (typeof e.data === 'string') {
        const response = mapResponseData(e.data);
        if (response[0] === 'AC0') {
          console.log('Sent successfully'); // Nothing to do now
        } else if (response[0] === 'CC0') {
          resolve();
        } else {
          if (!dontTerminateConnection) {
            wsClient.close();
          }
          return reject(e);
        }
      }
    };
  });
};

export const checkOutHandler = (wsClient, room, encoderNo, controllerWebsocketServer, lostKeyHelper, kioskSettings, setProcessStatus) => {
  if (parseInt(process.env.VUE_APP_DEBUG, 10) === 1) {
    return new Promise((resolve) => {
      resolve();
    });
  }

  return new Promise((resolve, reject) => {
    return lostKeyHandler(room.code, encoderNo, controllerWebsocketServer, kioskSettings, lostKeyHelper, wsClient)
      .then(() => {
        issueState = -1;
        sendDispenserCommand(COMMAND_ENABLE_INTAKING_CARD, wsClient);

        wsClient.onmessage = function(e) {
          console.log(issueState, e.data);
          if (typeof e.data === 'string') {
            const response = mapResponseData(e.data);
            if (response[0] === 'AC0') {
              console.log('Sent successfully'); // Nothing to do now
            } else if (response[0] === 'CC0') {
              try {
                if (issueState === -1) {
                  console.log('CC0 - intake card');
                  setProcessStatus('intaking');
                  issueState = 1;
                  sendDispenserCommand(COMMAND_INTAKE_CARD, wsClient);
                } else if (issueState === 0 && resetDone) {
                  console.log('CC0 - intake card after reset');
                  setProcessStatus('intaking');
                  issueState = 1;
                  sendDispenserCommand(COMMAND_INTAKE_CARD, wsClient);
                } else if (issueState === 1) {
                  console.log('State?:', e.data);
                  setTimeout(() => {
                    if (issueState !== 1) {
                      return;
                    }
                    if (e.data.match(/;CARDINSIDE/i)) {
                      console.log('CC0 - stop intaking');
                      setProcessStatus('processing');
                      issueState = 3;
                      sendDispenserCommand(COMMAND_STOP_INTAKING, wsClient);
                    } else {
                      sendDispenserCommand(COMMAND_DISPENSER_STATE, wsClient);
                    }
                  }, 1000);
                } else if (issueState === 3) {
                  console.log('CC0 - retreive card to stacker');
                  issueState = 4;
                  sendDispenserCommand(COMMAND_RETREIVE_TO_STACKER, wsClient);
                } else if (issueState === 4) {
                  setProcessStatus('intaking');
                  // wsClient.close();
                  return resolve();
                }

              } catch (e) {
                return reject(e);
              }
            } else {
              if (issueState === 1) {
                sendDispenserCommand(COMMAND_DISPENSER_STATE, wsClient);
                return;
              }
              if (resetDone || issueState > 0) { // Already reseted device and still getting an error
                wsClient.close();
                return reject(e);
              }

              // First error, reset device
              issueState = 0;
              resetDone = true;
              resetDispenser(wsClient);
            }
          }
        };

        wsClient.onclose = function() {
          resetDone = false;
        };
      })
      .catch((err) => {
        return reject(err);
      });
  });
};

export const createWsConnection = (controllerWebsocketServer) => {
  if (parseInt(process.env.VUE_APP_DEBUG, 10) === 1) {
    return new Promise((resolve) => {
      resolve(null);
    });
  }

  return new Promise((resolve, reject) => {
    try {
      const wsClient = wsHelperCreate(controllerWebsocketServer, reject);
      wsClient.onopen = function() {
        resolve(wsClient);
      };
    } catch (e) {
      return reject(e);
    }
  });
};

const sendCurrentHotelPin = (wsClient, propertyPin) => {
  wsClient.send('PIN;' + propertyPin);
};

const mapResponseData = (responseData) => {
  const responseArray = responseData.split(';');
  if (!responseArray[0]) {
    responseArray.push('CC1');
    responseArray.push('E0');
  }
  return responseArray;
};

export const resetDispenser = (wsClient) => {
  sendDispenserCommand(COMMAND_DISPENSER_RESET, wsClient);
};

export const cardHelperErrorHandle = (responseData) => {
  if (typeof responseData !== 'string' || !responseData.match(/^CC1/)) {
    return '';
  }

  const dataParts = responseData.split(';');

  if (dataParts[1] === 'F0') {
    return 'issue_card.alert.error.waiting_card_on_hold';
  }

  return 'issue_card.alert.error.general_error';
};
