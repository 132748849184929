export const encodeSaltoCard = (wsClient, encoderNo, kioskSettings, dateFrom, dateTo, room) => {
  const dateFromFormatted = dateFrom.format('0001DDMMYY');
  const dateToFormatted = dateTo.format('hhmmDDMMYY');
  const roomCodeNumber = /* process.env.VUE_APP_DEBUG ? '101' : */ room.code.toString();

  const roomDispatchedCards = room.dispatched_cards || 0;

  const encoderCommand = [
    roomDispatchedCards === 0 ? 'CN' : 'CC', // Descriptor of command ‘CN’[x] (CC for copy of room key)
    encoderNo, // Encoder number
    'E', // For motorised encoders - E contantly (not used)
    roomCodeNumber, // Room code
    '', // Room code 2
    '', // Room code 3
    '', // Room code 4
    '', // Authorisations granted
    '', // Authorisations denied
    dateFromFormatted, // Date from
    dateToFormatted, // Date to
    '', // Description of the operator
    '', // Info on track #1
    '', // Info on track #2
    '', // Info on track #3
    '', // Whether the PC interface is to return the card’s serial number or not
    '', // Authorization code assigned to the room guest
  ];

  wsClient.send(`SALTO;${kioskSettings['encoder_address']};${kioskSettings['encoder_port']};${encoderCommand.join('|')}`);
};

// Not used right now - only prepared.
export const encodeSaltoMobileKey = (wsClient, encoderNo, kioskSettings, dateFrom, dateTo, room) => {
  const dateFromFormatted = dateFrom.format('0001DDMMYY');
  const dateToFormatted = dateTo.format('hhmmDDMMYY');
  const roomCodeNumber = /* process.env.VUE_APP_DEBUG ? '101' : */ room.toString();

  const encoderCommand = [
    'CNM', // Descriptor of command ‘CNM‘
    '', // TBD Later - Mobile phone
    roomCodeNumber, // Room code
    '', // Room code 2
    '', // Room code 3
    '', // Room code 4
    '', // Authorisations granted
    '', // Authorisations denied
    dateFromFormatted, // Date from
    dateToFormatted, // Date to
    '', // Description of the operator
    '', // Info on track #1
    '', // Info on track #2
    '', // Info on track #3
    '', // Whether the PC interface is to return the card’s serial number or not
    '', // Authorization code assigned to the room guest
  ];

  wsClient.send(`SALTO;${kioskSettings['encoder_address']};${kioskSettings['encoder_port']};${encoderCommand.join('|')}`);
};

export const lostKeySalto = (wsClient, encoderNo, kioskSettings, roomCode) => {
  const roomCodeNumber = process.env.VUE_APP_DEBUG ? '101' : roomCode.toString();

  const encoderCommand = [
    'CP', // Descriptor of command ‘CP’
    encoderNo, // Encoder number
    roomCodeNumber, // Room code
  ];

  wsClient.send(`SALTO;${kioskSettings['encoder_address']};${kioskSettings['encoder_port']};${encoderCommand.join('|')}`);
};
