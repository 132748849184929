export const encodeDormakabaCard = (wsClient, encoderNo, kioskSettings, dateFrom, dateTo, room) => {
  const roomCodeNumber = /* process.env.VUE_APP_DEBUG ? '104' : */ room.code.toString();

  const roomDispatchedCards = room.dispatched_cards || 0;

  const encoderCommand = [
    '20', // Transaction request delimiter (20 fix)
    '00', // To SALOCK interface station (00 fix)
    '00', // From SALOCK interface station (00 fix)
    kioskSettings['pms_terminal_number'].padStart(3, '0'),
    roomDispatchedCards === 0 ? '001' : '003', // 001 = new key, 003 = copy key
    kioskSettings['saflock_password'].padEnd(7, 'S'), // Saflock password - 7 chars, fill unused with space - "S" (20H)
    roomCodeNumber.padEnd(5, 'S'), // Room code - 15 chars, fill unused with space - "S" (20H)
    '1', // Fixed 1 = Guest level
    encoderNo.padStart(2, '0'), // Encoder station number to make keys
    'FF', // Encoder led control information (Fixed FF)
    '01', // No. of keys to make
    dateTo.format('MMDDYY'), // MM DD YY - checkout date
    dateTo.format('hhmm'), // hh mm - checkout time
    dateTo.format('MMDDYY'), // MM DD YY - card expiration date
    dateTo.add(2, 'hours').format('hhmm'), // hh mm - card expiration time
    // '0', // 0 = add automatic pass number, 1 = ignore automatic pass number - can be ignored
    // '000000000000', // Pass numbers 12 to 1 - can be ignored
  ];

  wsClient.send(`DORMAKABA;${kioskSettings['encoder_address']};${kioskSettings['encoder_port']};${encoderCommand.join('')}`);
};

export const lostKeyDormakaba = (/* wsClient, kioskSettings, roomCode */) => {
  return null;

  // Does not work now with Dormakaba Ambiance, prepared for future usage

  /* const roomCodeNumber = process.env.VUE_APP_DEBUG ? '104' : roomCode.toString();

  const encoderCommand = [
    '20', // Transaction request delimiter (20 fix)
    '00', // To SALOCK interface station (00 fix)
    '00', // From SALOCK interface station (00 fix)
    kioskSettings['pms_terminal_number'].padStart(3, '0'),
    '015', // 015 = checkout by room
    kioskSettings['saflock_password'].padEnd(7, 'S'), // Saflock password - 7 chars, fill unused with space - "S" (20H)
    roomCodeNumber.padEnd(5, 'S'), // Room code - 15 chars, fill unused with space - "S" (20H)
    '1', // Fixed 1 = Guest level
  ];

  wsClient.send(`DORMAKABA;${kioskSettings['encoder_address']};${kioskSettings['encoder_port']};${encoderCommand.join('')}`); */
};
