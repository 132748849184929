export const encodeAssaAbloyCard = (wsClient, encoderNo, kioskSettings, dateFrom, dateTo, room) => {
  const dateFromFormatted = dateFrom.format('YYYYMMDD0001');
  const dateToFormatted = dateTo.format('YYYYMMDDhhmm');
  const roomCodeNumber = /* process.env.VUE_APP_DEBUG ? '102' : */ room.code.toString();
  wsClient.send(`ASSAVING;${kioskSettings['encoder_address']};${kioskSettings['encoder_port']};CCA;EA${encoderNo};CI${dateFromFormatted};CO${dateToFormatted};GR${roomCodeNumber};`);
};

export const lostKeyAssaAbloy = (wsClient, encoderNo, kioskSettings, roomCode) => {
  const roomCodeNumber = /* process.env.VUE_APP_DEBUG ? '102' : */ roomCode.toString();
  wsClient.send(`ASSAVING;${kioskSettings['encoder_address']};${kioskSettings['encoder_port']};CCG;EA${encoderNo};GR${roomCodeNumber};`);
};
