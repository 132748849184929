export const createGuest = (wsClient, propertySettings, encoderData, dateFrom, dateTo, room, cisaUserCode, cisaPassCode) => {
  const dateFromFormatted = dateFrom.format('DD-MM-YYYY');
  const timeFromFormatted = '00:01';
  const dateToFormatted = dateTo.format('DD-MM-YYYY');
  const timeToFormatted = dateTo.format('hh:mm');
  const encoderAddress = propertySettings['encoder_address'];
  const encoderPort = propertySettings['encoder_port'];
  wsClient.send(`CISACREATE;${encoderAddress};${encoderPort};${encoderData};${dateFromFormatted};${timeFromFormatted};${dateToFormatted};${timeToFormatted};${room.code};${cisaUserCode};${cisaPassCode};`);
};

export const lostkeyCisa = (wsClient, propertySettings, roomCode) => {
  const encoderAddress = propertySettings['encoder_address'];
  const encoderPort = propertySettings['encoder_port'];
  wsClient.send(`CISACOROOM;${encoderAddress};${encoderPort};${roomCode};`);
};
