export const encodeTesaCard = (wsClient, encoderNo, kioskSettings, dateFrom, dateTo, room) => {
  const roomCodeNumber = /* process.env.VUE_APP_DEBUG ? '201' : */ room.code.toString();
  const roomDispatchedCards = room.dispatched_cards || 0;
  const command = [
    roomDispatchedCards === 0 ? 'CI' : 'CG', // Command
    roomCodeNumber, // Room code
    dateFrom.format('DD/MM/YYYY'), // From date
    dateFrom.format('hh:mm'), // From time
    dateTo.format('DD/MM/YYYY'), // To date
    dateTo.format('hh:mm'), // To time
    '', // Grants
    '', // Keypad,
    'EF', // Card operation - default EF
    '', // Operator, can be leaves empty
    encoderNo, // Tesa hotel encoder
    '', // Track 1
    '', // Track 2
    'P', // Technology
    '', // Room 2
    '', // Room 3
    '', // Room 4
    '0', // Return card ID in responce (boolean)
    '', // Card ID - unique card identification
    '', // Card type - Encoder zero only - can be left blank
    '', // Phone number
    '', // Mail 1
    '', // Mail 2
    '', // Mail 3
    '', // Mail 4
  ];
  wsClient.send(`AATESA;${kioskSettings['encoder_address']};${kioskSettings['encoder_port']};${command.join('|')}`);
};

export const lostKeyTesa = (wsClient, kioskSettings, roomCode) => {
  const roomCodeNumber = /* process.env.VUE_APP_DEBUG ? '201' : */ roomCode.toString();
  const command = [
    'CO', // Command
    roomCodeNumber, // Room code
    '', // Operator, can be leaves empty
  ];
  wsClient.send(`AATESA;${kioskSettings['encoder_address']};${kioskSettings['encoder_port']};${command.join('|')};`);
};
