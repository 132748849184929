export const prepareOnityCardCode = (wsClient, encoderNo, kioskSettings, dateFrom, dateTo, room) => {
  const dateFromFormatted = dateFrom.format('00DDMMYY');
  const dateToFormatted = dateTo.format('hhDDMMYY');
  const roomCodeNumber = /* process.env.VUE_APP_DEBUG ? '104' : */ room.code.toString();

  const roomDispatchedCards = room.dispatched_cards || 0;

  const encoderCommand = [
    kioskSettings['encoder_address'],
    kioskSettings['encoder_port'],
    roomDispatchedCards === 0 ? 'CN1' : 'CC1', // Descriptor of command ‘CN’[x] (CC for copy of room key)
    encoderNo, // encoderNo, // Encoder number
    'E', // For motorised encoders - E contantly (not used)
    roomCodeNumber, // Room code
    '', // Room code 2
    '', // Room code 3
    '', // Room code 4
    '', // Authorisations granted
    '', // Authorisations denied
    dateFromFormatted, // Date from
    dateToFormatted, // Date to
    'Mystay', // Description of the operator
    '', // Info on track #1
    '', // Info on track #2
    '', // Info on track #3
    '', // Whether the PC interface is to return the card’s serial number or not
    '', // Authorization code assigned to the room guest
  ];

  wsClient.send(`ONITY;${encoderCommand.join('|')}`);
};

export const encodeOnityCard = (wsClient, encoderData) => {
  console.log('encoderData', encoderData);
  wsClient.send(`ONITY;ENCODE;${encoderData}`);
};

export const lostKeyOnity = (wsClient, kioskSettings, roomCode) => {
  const roomCodeNumber = /* process.env.VUE_APP_DEBUG ? '104' : */ roomCode.toString();

  const encoderCommand = [
    kioskSettings['encoder_address'],
    kioskSettings['encoder_port'],
    'CO', // Descriptor of command ‘CP’
    0, // encoderNo, // Encoder number
    roomCodeNumber, // Room code
  ];

  wsClient.send(`ONITY;${encoderCommand.join('|')}`);
};
