<template>
  <div class="room-item">
    <div class="room-item__description">
      <div class="room-item__description__info">
        <strong class="room-name">{{ $t('room_item.detail.room_number') }} {{ room.name }}</strong>
        <span class="room-type">{{ roomType }}</span>
        <div class="dispatched-cards">
          <slot name="dispatchedCards"></slot>
        </div>
      </div>
      <div class="room-item__description__icon">
        <DoorIcon />
      </div>
    </div>
    <div class="room-item__action">
      <slot name="action"></slot>
    </div>

  </div>
</template>

<script>
import DoorIcon from '@/assets/images/icons/open-door.svg';
import { getTranslated } from '@/helpers/translations';
import _ from 'lodash';
import {
  GET_ROOMS,
} from '@/store/reservation';
import { mapGetters } from 'vuex';

export default {
  name: 'RoomItem',
  components: {
    DoorIcon,
  },
  props: {
    room: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      rooms: GET_ROOMS,
    }),
    roomType() {
      if (getTranslated(_.get(this.room, 'room_type'), this.$i18n.locale)) {
        return getTranslated(_.get(this.room, 'room_type'), this.$i18n.locale);
      }
      return this.room.room_type.en_GB;
    },
  },
};
</script>

<style lang="scss">
  .room-item {
    text-align: left;
    display: block;
    max-width: 22rem;
    width: 50%;
    margin: 0 .5rem;
    flex: 0 50%;

    @media (min-height: 1000px) and (max-width: 1200px) {
      flex: 0 100%;
    }

    @media (max-width: 1024px) {
      flex: 0 100%;
    }

    &__description {
      display: flex;
      background: $secondary-body-bg;
      box-shadow: 0 0 .25rem 0 rgba(0,0,0,.15);
      border-radius: .625rem;
      padding: 1rem 2rem;
      margin-bottom: .5rem;
      align-items: center;

      &__info {
        display: flex;
        flex-direction: column;
        flex: 1;
      }

      &__icon {
        width: 3rem;
        display: flex;
      }
    }

    &__action {
      margin-bottom: 2rem;

      .button {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          width: 2rem;
          height: 2rem;
          margin-right: 1rem;
        }
      }
    }

    .room-name {
      font-size: 1.25rem;
      line-height: 2rem;
      display: block;
      margin-bottom: .1rem;
    }

    .room-type {
      font-size: .875rem;
      margin-bottom: 1rem;
      display: block;
    }

    .dispatched-cards {
      display: block;
      font-size: .875rem;
    }
  }
</style>